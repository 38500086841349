import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../components/Layouts/layout';
import SEO from '../components/seo';

const TermsPage = () => {
	const intl = useIntl();

	return (
		<Layout header={<FormattedMessage id='termsOfUse.heading' />}>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.termsDescription'
				})}
				lang={intl.locale}
				title={intl.formatMessage({ id: 'termsOfUse.heading' })}
			/>
			<Container>
				<Row>
					<Col md={12}>
						<p>
							<b>
								<FormattedMessage id='termsOfUse.1' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.1.1' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.1.2' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.1.3' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.1.4' />
						</p>

						<p>
							<b>
								<FormattedMessage id='termsOfUse.2' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.2.1' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.2.2' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.2.3' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.2.4' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.2.5' />
						</p>

						<p>
							<b>
								<FormattedMessage id='termsOfUse.3' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.3.1' />
						</p>

						<p>
							<b>
								<FormattedMessage id='termsOfUse.4' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.4.1' />
						</p>

						<p>
							<b>
								<FormattedMessage id='termsOfUse.5' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.5.1' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.5.2' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.5.3' />
						</p>

						<p>
							<b>
								<FormattedMessage id='termsOfUse.6' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.6.1' />
							<ol>
								<li>
									<FormattedMessage id='termsOfUse.6.1A' />
								</li>
								<li>
									<FormattedMessage id='termsOfUse.6.1B' />
								</li>
								<li>
									<FormattedMessage id='termsOfUse.6.1C' />
								</li>
								<li>
									<FormattedMessage id='termsOfUse.6.1D' />
								</li>
								<li>
									<FormattedMessage id='termsOfUse.6.1E' />
								</li>
								<li>
									<FormattedMessage id='termsOfUse.6.1F' />
									<ul>
										<li>
											<FormattedMessage id='termsOfUse.6.1G' />
										</li>
										<li>
											<FormattedMessage id='termsOfUse.6.1H' />
										</li>
									</ul>
								</li>
							</ol>
						</p>

						<p>
							<b>
								<FormattedMessage id='termsOfUse.7' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.7.1' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.7.2' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.7.3' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.7.4' />
						</p>

						<p>
							<b>
								<FormattedMessage id='termsOfUse.8' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.8.1' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.8.2' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.8.3' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.8.4' />
						</p>

						<p>
							<b>
								<FormattedMessage id='termsOfUse.9' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.9.1' />
							<br />
							<a href='../../../Administrative Agreement Jan 29 2021.pdf'>
								<FormattedMessage id='linkNames.adminAgreement' />
							</a>
						</p>

						<p>
							<b>
								<FormattedMessage id='termsOfUse.10' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.10.1' />
							<ol>
								<li>
									<FormattedMessage id='termsOfUse.10.1A' />
								</li>
								<li>
									<FormattedMessage id='termsOfUse.10.1B' />
								</li>
								<li>
									<FormattedMessage id='termsOfUse.10.1C' />
								</li>
								<li>
									<FormattedMessage id='termsOfUse.10.1D' />
								</li>
								<li>
									<FormattedMessage id='termsOfUse.10.1E' />
								</li>
							</ol>

							<br />

							<FormattedMessage id='termsOfUse.10.2' />
							<ol>
								<li>
									<FormattedMessage id='termsOfUse.10.2A' />
								</li>
								<li>
									<FormattedMessage id='termsOfUse.10.2B' />
								</li>
								<li>
									<FormattedMessage id='termsOfUse.10.2C' />
								</li>
							</ol>
							<br />
							<FormattedMessage id='termsOfUse.10.3' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.10.4' />
						</p>

						<p>
							<b>
								<FormattedMessage id='termsOfUse.11' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.11.1' />
							<br />
							<br />
							<FormattedMessage id='termsOfUse.11.2' />
						</p>

						<p>
							<b>
								<FormattedMessage id='termsOfUse.12' />
							</b>
							<br />
							<FormattedMessage id='termsOfUse.12.1' />
						</p>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default TermsPage;
